@import "./theme.css";
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");
body {
  background: var(--smoky-black);
  color: var(--white-2);
  font-family: "EB Garamond", serif;
  transition: all 1s ease;
}
a {
  color: inherit;
  text-decoration: none;
}
