
.navigation ul {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}

.navigation ul li {
  list-style-type: none;
}

.navigation a.active {
  color: var(--blue-light);
  font-size: x-large;
}

