.timelineHeader {
  display: flex;
}

.timelineList {
  list-style-type: none;
  margin-left: 45px;
}
.timelineList > li {
  margin-bottom: 20px;
  position: relative;
}

.timelineList > li:not(:last-child)::before {
  content: "";
  position: absolute;
  width: 1px;
  top: -30px;
  left: -53px;
  height: calc(100% + 65px);
  background-color: var(--jet);
  box-sizing: border-box;
}

.timelineList > li::after {
  content: "";
  position: absolute;
  top: 5px;
  left: -56px;
  height: 6px;
  width: 6px;
  background: var(--text-gradient-blue);
  border-radius: 50%;
  box-shadow: 0 0 0 4px var(--jet);
}
.timelineList > li > h4 {
  margin-bottom: 7px;
  text-transform: capitalize;
  line-height: 1.3;
}

.timelineList > li > h5 {
  margin-bottom: 3px;
  margin-top: 0px;
}
.time {
  color: var(--blue-light);
  font-weight: 400;
  line-height: 1.6;
  text-transform: uppercase;
}

.subtitle {
  font-style: italic;
  text-transform: uppercase;
}
