.PortfolioItem {
  border: 1px solid var(--jet);
  border-radius: 20px;
  padding: 20px;
  margin: 15px;
}

.PortfolioItem h3 {
  margin: 0px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.links > * {
  padding: 5px;
  margin: 2px;
}
.header {
  display: flex;
  justify-content: space-between;
}

.PortfolioItem p {
  margin: 0px;
}

@media screen and (min-width: 960px) {
  .PortfolioItem {
    width: 300px;
  }
}