.mainWrapper {
  display: flex;
  margin: 15px 12px;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 70px;
}

.bottomNavigation {
  position: fixed;
  bottom: 0;
  left: 0;
  background: hsla(240, 1%, 17%, 0.75);
  width: 100%;
  backdrop-filter: blur(10px);
  border: 1px solid var(--jet);
  box-shadow: var(--shadow-2);
  border-radius: 20px 20px 0 0;
  z-index: 5;
}

@media screen and (min-width: 600px) {
  .mainWrapper {
    margin-top: 60px;
    row-gap: 40px;
  }

  .bottomNavigation {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .mainWrapper {
    flex-direction: row;
    column-gap: 40px;
    align-items: start;
  }
  .mainWrapper > section:last-child {
    flex-grow: 1;
  }
}
