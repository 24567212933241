.iconBox {
  margin: 10px;
  display: inline-flex;
  align-items: center;
}

.icon {
  color: var(--blue);
  font-size: xx-large;
  background: var(--glass-overlay);
  box-shadow: var(--shadow-1);
  border-radius: 12px;
  padding: 10px;
  z-index: 1;
}

.infoBox {
  margin: 15px;
  display: inline-block;
}

.infoBox a,
.infoBox p {
  display: block;
  color: inherit;
  margin: 0;
}

.title {
  text-align: start;
  text-transform: uppercase;
  color: var(--light-gray-70);
}
